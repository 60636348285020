import {
  vBillBatchChatPanelQSKey,
  vBillBatchChatPanels,
  vBillBatchSidebarPanelQSKey,
  vBillBatchSidebarPanels,
  vBillChatPanelQSKey,
  vBillChatPanels,
  vBillSidebarPanelQSKey,
  vBillSidebarPanels,
  vCheckChatPanelQSKey,
  vCheckChatPanels,
  vCheckSidebarPanelQSKey,
  vCheckSidebarPanels,
} from "common/helpers/queryString";
import {
  IChatChannelParentType,
  IChatChatIoChannelOutputSchema,
  IChatContentBlockMessageRelatedType,
} from "generated/sdk.chat";

export const CHAT_MSG_REACTION_BLOCK_PREFIX = `${IChatContentBlockMessageRelatedType.Reaction}_block`;
export const CHAT_MSG_REACTION_INLINE_PREFIX = `${IChatContentBlockMessageRelatedType.Reaction}_inline`;

export type IGetChatNotificationUrlArgs = Pick<IChatChatIoChannelOutputSchema, "parentType" | "parentContext" | "url">;

export const getChatNotificationUrl = ({ parentType, parentContext, url }: IGetChatNotificationUrlArgs) => {
  const searchParams = new URLSearchParams();

  if (parentType === IChatChannelParentType.Vcheck) {
    searchParams.set(vCheckSidebarPanelQSKey, vCheckSidebarPanels.CHAT);

    if (parentContext === "receiving" || parentContext === "sending") {
      searchParams.set(vCheckChatPanelQSKey, vCheckChatPanels.SR_CHAT);
    } else if (parentContext === "receiving-admin" || parentContext === "sending-admin") {
      searchParams.set(vCheckChatPanelQSKey, vCheckChatPanels.SUPPORT_CHAT);
    } else {
      searchParams.set(vCheckChatPanelQSKey, vCheckChatPanels.RS_CHAT);
    }
  }

  if (parentType === IChatChannelParentType.Vbill) {
    searchParams.set(vBillSidebarPanelQSKey, vBillSidebarPanels.CHAT);

    if (parentContext === "sending-admin") {
      searchParams.set(vBillChatPanelQSKey, vBillChatPanels.SUPPORT_CHAT);
    } else if (parentContext === "sending") {
      searchParams.set(vBillChatPanelQSKey, vBillChatPanels.SR_CHAT);
    } else {
      searchParams.set(vBillChatPanelQSKey, vBillChatPanels.RS_CHAT);
    }
  }

  if (parentType === IChatChannelParentType.VbillBatch) {
    searchParams.set(vBillBatchSidebarPanelQSKey, vBillBatchSidebarPanels.CHAT);

    if (parentContext === "sending-admin") {
      searchParams.set(vBillBatchChatPanelQSKey, vBillBatchChatPanels.SUPPORT_CHAT);
    } else if (parentContext === "sending") {
      searchParams.set(vBillBatchChatPanelQSKey, vBillBatchChatPanels.SR_CHAT);
    } else {
      searchParams.set(vBillBatchChatPanelQSKey, vBillBatchChatPanels.RS_CHAT);
    }
  }

  return `${url}?${searchParams.toString()}`;
};
