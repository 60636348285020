import { ChatChannelStore } from "modules/Chat/stores/ChatChannelStore";
import { getChatChannelStore } from "modules/Chat/stores/chatChannelStoresCache";
import { TBaseIoChannel } from "modules/Chat/stores/types";
import { FunctionComponent, createContext, useMemo } from "react";

export const ChatChannelStoreContext = createContext<ChatChannelStore | undefined>(undefined);

interface IChatChannelStoreProviderProps {
  baseIoChannel: TBaseIoChannel;
}

export const ChatChannelStoreProvider: FunctionComponent<IChatChannelStoreProviderProps> = ({
  baseIoChannel,
  children,
}) => {
  const chatChannelStoreProviderValue = useMemo(() => getChatChannelStore(baseIoChannel), [baseIoChannel]);

  return (
    <ChatChannelStoreContext.Provider value={chatChannelStoreProviderValue}>
      {children}
    </ChatChannelStoreContext.Provider>
  );
};
