import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { AdminChat } from "./AdminChat";
import "./fonts/fonts.scss";

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" defaultLocale="en">
      <AdminChat />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById(import.meta.env.VITE_APP_REACT_DOM_NODE ?? "root"),
);
