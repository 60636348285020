import { Box, Typography } from "@mui/material";
import { IChatChatIoMessageOutputSchema } from "generated/sdk.chat";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent } from "react";
import { ChatMessageBlockContent } from "../ChatMessageBlockContent/ChatMessageBlockContent";

interface IMessageContentReplyProps {
  message: IChatChatIoMessageOutputSchema;
}

export const MessageContentReply: FunctionComponent<IMessageContentReplyProps> = observer(
  ({ message: { replyToMessage } }) => {
    const chatChannelStore = useChatChannelStore();

    if (!replyToMessage) {
      return null;
    }

    const isMessageOwner = replyToMessage.memberId === chatChannelStore?.currentSessionMember?.id;
    const member = chatChannelStore?.members?.find(({ id }) => id === replyToMessage.memberId);

    return (
      <Box
        sx={{
          backgroundColor: isMessageOwner ? "#e5f0f3" : "#efefef",
          borderRadius: "10px",
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: 14, marginBottom: "5px" }}>
          Reply to:{" "}
          <strong style={{ textDecoration: "underline" }}>{isMessageOwner ? "You" : (member?.name ?? "")}</strong>
        </Typography>

        <ChatMessageBlockContent content={replyToMessage.content} isMessageOwner={isMessageOwner} />
      </Box>
    );
  },
);
