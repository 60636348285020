export const appConfig = {
  VITE_APP_WATCHDOG_TIMEOUT: (window as any).reactConfig?.watchdogTimeout ?? 5000,
  VITE_APP_GRAPHQL_ENDPOINT: (window as any).reactConfig?.graphQLApiBase ?? "/graphql/frontend",
  VITE_APP_GRAPHQL_CHECK_BATCHING_ENDPOINT:
    (window as any).reactConfig?.graphQLCheckBatchingApiBase ?? "/graphql/check_batch",
  VITE_APP_GRAPHQL_VBILL_ENDPOINT: (window as any).reactConfig?.graphQLVBillApiBase ?? "/graphql/vbill",
  VITE_APP_GRAPHQL_ACTION_LOG_ENDPOINT: (window as any).reactConfig?.graphQLActionLogApiBase ?? "/graphql/actionlog",
  VITE_APP_GRAPHQL_TASK_STORE_ENDPOINT: (window as any).reactConfig?.graphQLTaskStoreApiBase ?? "/graphql/taskstore",
  VITE_APP_GRAPHQL_CHAT_ENDPOINT: (window as any).reactConfig?.graphQLChatApiBase ?? "/graphql/chat",
  VITE_APP_FILESTORE_ENDPOINT: (window as any).reactConfig?.graphQLChatApiBase ?? "/api/filestore",
};
