import { Box, SxProps, Theme } from "@mui/material";
import { graphqlChatClient } from "common/graphqlClient";
import { Spinner } from "components/common/Spinner/Spinner";
import { getSdk } from "generated/sdk.chat";
import { initNewChatChannelStore } from "modules/Chat/stores/chatChannelStoresCache";
import { IChatChannelStoreInit } from "modules/Chat/stores/types";
import { FunctionComponent, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { ChatRestrictedChannel } from "../common/ChatRestrictedChannel/ChatRestrictedChannel";
import { ChatViewChannel } from "../common/ChatViewChannel/ChatViewChannel";
import { ChannelComingSoonLayer } from "./ChannelComingSoonLayer/ChannelComingSoonLayer";

const { ChatGetChannel } = getSdk(graphqlChatClient);

export enum ChannelDashboardView {
  VIEW_CHANNEL,
  RESTRICTED_CHANNEL,
}

interface IChatDetailDashboardProps extends IChatChannelStoreInit {
  sx?: SxProps<Theme>;
  channelSx?: SxProps<Theme>;
}

export const ChatDetailDashboard: FunctionComponent<IChatDetailDashboardProps> = ({
  sx,
  channelSx,
  config,
  settings,
  relatedItems,
  possibleMembers,
}) => {
  const [isChannelReqLoading, setIsChannelReqLoading] = useState(false);
  const [dashboardView, setDashboardView] = useState<ChannelDashboardView>();

  useDeepCompareEffect(() => {
    const { parentContext, parentKey, parentType } = config.baseIoChannel;

    if (!parentContext?.length || !parentKey?.length || !parentType?.length) {
      return;
    }

    loadChannelDetails({ config, settings, relatedItems, possibleMembers });
  }, [config, settings]);

  const loadChannelDetails = async (data: IChatChannelStoreInit) => {
    setIsChannelReqLoading(true);
    const getChannelResp = await ChatGetChannel({ channelIo: data.config.baseIoChannel });

    if (getChannelResp?.getChannel?.error) {
      setDashboardView(ChannelDashboardView.RESTRICTED_CHANNEL);
    } else {
      initNewChatChannelStore(data);
      setDashboardView(ChannelDashboardView.VIEW_CHANNEL);
    }

    setIsChannelReqLoading(false);
  };

  return (
    <Box sx={{ position: "relative", maxWidth: "600px", ...sx }}>
      {isChannelReqLoading ? (
        <Spinner withSpaceAround />
      ) : (
        <>
          {dashboardView === ChannelDashboardView.RESTRICTED_CHANNEL && <ChatRestrictedChannel />}

          {dashboardView === ChannelDashboardView.VIEW_CHANNEL && (
            <ChatViewChannel baseIoChannel={config.baseIoChannel} sx={channelSx} />
          )}

          {settings?.channelComingSoon && <ChannelComingSoonLayer />}
        </>
      )}
    </Box>
  );
};
