export const vCheckSidebarPanelQSKey = "sidebar_panel";
export enum vCheckSidebarPanels {
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
}
export const vCheckChatPanelQSKey = "chat_panel";
export enum vCheckChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

export const vBillBatchSidebarPanelQSKey = "sidebar-tab-panel";
export enum vBillBatchSidebarPanels {
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
  "STATS" = "stats",
  "PAYMENTS" = "payments",
}
export const vBillBatchChatPanelQSKey = "chat_panel";
export enum vBillBatchChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

export const vBillSidebarPanelQSKey = "sidebar-tab-panel";
export enum vBillSidebarPanels {
  "ATTACHMENTS" = "attachments",
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
  "BILL_PAYMENTS" = "bill_payments",
  "APPROVAL" = "approval",
  "EMAIL" = "email",
}
export const vBillChatPanelQSKey = "chat_panel";
export enum vBillChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}
