import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ScopedCssBaseline } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "components/common/Errors/ErrorBoundary";
import { merge } from "lodash";
import { ChatDetailDashboard, TBaseIoChannel } from "modules/Chat";
import { ChatSummaryBadge } from "modules/Chat/components/ChatSummaryBadge/ChatSummaryBadge";
import { IGetChatNotificationUrlArgs, getChatNotificationUrl } from "modules/Chat/utils/utils";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { StoreContainerContext } from "storeContainer";
import { IStoreContainer, initStoreContainer } from "storesMobx";
import { defaultTheme } from "themes";

// becuase admin uses tailwindcss there are some css values that we can't override so we need to specify !important
const overrideDetaultTheme = {
  components: { MuiButton: { styleOverrides: { contained: { background: "#2877EC !important" } } } },
};
const adminDefaultTheme = merge(defaultTheme, overrideDetaultTheme);

const cache = createCache({
  key: "css",
  //TODO declare global type for Window https://bobbyhadz.com/blog/typescript-extend-window
  nonce: (window as any).nonce || "",
  prepend: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const storeContainer: IStoreContainer = initStoreContainer();

interface IAdminComponentChat {
  component: "chat";
  props: {
    config: {
      baseIoChannel: TBaseIoChannel;
      sessionAccountId: string;
      organizationId: string;
      channelCanonicalUrl: string;
    };
    settings: {
      showMembers?: boolean;
      showVisibility?: boolean;
      forcePublic?: boolean;
    };
  };
  targetElementId: string;
}

interface IAdminComponentChatBadge {
  component: "chatBadge";
  props: {};
  targetElementId: string;
}

type IAdminComponent = IAdminComponentChat | IAdminComponentChatBadge;

// () => {
// This is just and example how to use window.adminComponents
// (window as any).adminComponents = (window as any).window.adminComponents || [];
// (window as any).adminComponents.push({
//   component: "chat",
//   props: {
//     config: {
//       baseIoChannel: {
//         parentContext: "vbill_tab",
//         parentKey: "133123",
//         parentType: IChatChannelParentType.Vbill,
//       },
//       sessionAccountId: "a",
//       organizationId: "b4zNRPQqkA",
//       channelCanonicalUrl: "dddd",
//     },
//   },
//   targetElementId: "admin-chat-channel",
// } as IAdminComponentChat);

// (window as any).adminComponents.push({
//   component: "chatBadge",
//   props: {},
//   targetElementId: "admin-chat-notifications",
// } as IAdminComponentChatBadge);
// };

export const AdminChat = () => {
  const [components, setComponents] = useState<any[]>([]);

  const componentPusher = (component: IAdminComponent) => {
    console.log("componentPusher", component);
    setComponents((prev) => [...prev, component]);
  };

  useEffect(() => {
    (window as any).adminComponents = (window as any).window.adminComponents || [];
    console.log("initial adminComponents", (window as any).adminComponents);
    (window as any).adminComponents.forEach((component: IAdminComponent) => {
      componentPusher(component);
    });
    (window as any).adminComponents = { push: componentPusher };
  }, []);

  const handleChatSummaryChannelNotificationClick = (channel: IGetChatNotificationUrlArgs) => {
    // TODO:
    const url = getChatNotificationUrl(channel);

    (window as Window).location = url;
  };

  return (
    <CacheProvider value={cache}>
      <ScopedCssBaseline>
        <ThemeProvider theme={adminDefaultTheme}>
          <ErrorBoundary>
            <StoreContainerContext.Provider value={storeContainer}>
              <QueryClientProvider client={queryClient}>
                {components.map((component: IAdminComponent, index: number) => {
                  const targetElement = document.getElementById(component.targetElementId) as HTMLElement;
                  if (!targetElement) {
                    console.error("Can't find target element for component", component);
                    return null;
                  }
                  return (
                    <>
                      {component.component === "chat" &&
                        createPortal(<ChatDetailDashboard {...component.props} />, targetElement)}
                      {component.component === "chatBadge" &&
                        createPortal(
                          <ChatSummaryBadge
                            onChannelNotificationClick={handleChatSummaryChannelNotificationClick}
                            {...component.props}
                          />,
                          targetElement,
                        )}
                    </>
                  );
                })}
              </QueryClientProvider>
            </StoreContainerContext.Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </ScopedCssBaseline>
    </CacheProvider>
  );
};
