import { graphqlVBillClient } from "common/graphqlClient";
import { transformDatesAsSODUTCIsoString } from "components/pages/common/VBill/utils";
import {
  getSdk,
  IVBillBatchListFiltersInput,
  IVBillBatchPaginationInput,
  IVBillBillsFiltersInput,
  IVBillInvoicePaginationInput,
  IVBillReactionParentType,
  IVBillVBillAddInvoicesToBatchMutationVariables,
  IVBillVBillAddMembersToBatchMutationVariables,
  IVBillVBillCompaniesSettingsQuery,
  IVBillVBillCompaniesSettingsQueryVariables,
  IVBillVBillCreateBatchPaymentsMutationVariables,
  IVBillVBillGetBatchListQuery,
  IVBillVBillGetBatchMembersQuery,
  IVBillVBillGetBatchMembersQueryVariables,
  IVBillVBillGetBatchTreeQuery,
  IVBillVBillGetBillsQuery,
  IVBillVBillGetBillsQueryVariables,
  IVBillVBillGetBillsStatsQuery,
  IVBillVBillGetBillsStatsQueryVariables,
  IVBillVBillGetReactionsStatsQuery,
  IVBillVBillRemoveInvoicesFromBatchMutationVariables,
  IVBillVBillRemoveMembersFromBatchMutationVariables,
  IVBillVBillSettingsQuery,
  IVBillVBillSettingsQueryVariables,
  IVBillVBillUpdateBatchMutationVariables,
} from "generated/sdk.vbill";
import { isFunction } from "lodash";
import { action, makeObservable, observable, runInAction } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const {
  VBillSettings,
  VBillCompaniesSettings,
  VBillAddInvoicesToBatch,
  VBillGetBatchList,
  VBillRemoveInvoicesFromBatch,
  VBillGetBills,
  VBillGetBillsStats,
  VBillGetBatchMembers,
  VBillAddMembersToBatch,
  VBillRemoveMembersFromBatch,
  VBillUpdateBatch,
  VBillGetReactionsStats,
  VBillGetBatchTree,
  VBillCreateBatchPayments,
  VBillUpdateBatchStatus,
} = getSdk(graphqlVBillClient);

// TODO: rename VBillGetBatchList to VBillGetBatchDetails VBillGetBatchChildListDetails

type BatchNestedData = NonNullable<IVBillVBillGetBatchTreeQuery["batchData"]["data"]>["items"][number];
export interface IBatchNestedTree extends BatchNestedData {
  children: IBatchNestedTree[];
}
interface IBatchNestedTreeReqData {
  organizationId: string;
  batchId: number;
}

interface IVBillBillsAvaExtendedFiltersInput extends IVBillBillsFiltersInput {
  in_batch?: boolean;
  in_other_batches?: boolean;
}
interface IVBillBatchDetailsFilters {
  // Selected VBills
  vbill_sel_fil?: IVBillBillsFiltersInput;
  vbill_sel_pag: IVBillInvoicePaginationInput;
  // Available VBills
  vbill_ava_fil?: IVBillBillsAvaExtendedFiltersInput;
  vbill_ava_pag: IVBillInvoicePaginationInput;
  // Sub Batches
  batch_list_fil?: Partial<IVBillBatchListFiltersInput>;
  batch_list_pag: IVBillInvoicePaginationInput;
}
const initialBatchDetailsFiltersPaginationParams: IVBillInvoicePaginationInput = { page: 1, per_page: 10 };
const batchDetailsFiltersInitParams: IVBillBatchDetailsFilters = {
  vbill_sel_fil: undefined,
  vbill_sel_pag: initialBatchDetailsFiltersPaginationParams,
  vbill_ava_fil: undefined,
  vbill_ava_pag: initialBatchDetailsFiltersPaginationParams,
  batch_list_fil: undefined,
  batch_list_pag: initialBatchDetailsFiltersPaginationParams,
};

export type TStatsFiltersAppliedForBillsList = "selected" | "available";

export class VBillBatchDetailsStore extends StoreBase {
  DEMO_batch_recurringDate: { value: undefined | Date; type: string | undefined } = {
    value: undefined,
    type: undefined,
  };

  statsFiltersAppliedForBills: TStatsFiltersAppliedForBillsList | undefined;

  constructor() {
    super();
    makeObservable(this, {
      statsFiltersAppliedForBills: observable,
      setStatsFiltersAppliedForBills: action,
      batchFilters: observable,
      DEMO_batch_recurringDate: observable,
      SET_DEMO_batch_recurringDate: action,
      setBatchFilters: action,
      resetBatchFilters: action,
    });
  }

  setStatsFiltersAppliedForBills = (value?: TStatsFiltersAppliedForBillsList) => {
    this.statsFiltersAppliedForBills = value;
  };

  SET_DEMO_batch_recurringDate = (value: undefined | Date, type: string | undefined) => {
    this.DEMO_batch_recurringDate = { value, type };
  };

  // filters
  batchFilters: IVBillBatchDetailsFilters = batchDetailsFiltersInitParams;

  // vBill additional mappings settings used details drawer
  vBillAdditionalMappingsSettings =
    createObservableContainer<IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"]>();

  // batch tree
  batchNestedTree = createObservableContainer<IBatchNestedTree>();

  // additional mappings settings used in filters / bill line
  vBillBatchAdditionalMappingsSettings =
    createObservableContainer<IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]>();

  // batch
  batchDetails = createObservableContainer<
    NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number] & {
      settings?: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["settings"];
    }
  >();
  batchDetailsReactionsStats =
    createObservableContainer<IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"]>();
  batchMembers = createObservableContainer<IVBillVBillGetBatchMembersQuery["getBatchMembers"]["data"]>();
  batchStats = createObservableContainer<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();

  childBatchListDetails = createObservableContainer<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>();
  childBatchListDetailsReactionsStats =
    createObservableContainer<IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"]>();

  batchSelectedVBills = createObservableContainer<IVBillVBillGetBillsQuery["getBills"]>();
  batchSelectedVBillsReactionsStats =
    createObservableContainer<IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"]>();

  batchAvailableVBills = createObservableContainer<IVBillVBillGetBillsQuery["getBills"]>();

  // filters, setBatchFilters as react useState :)
  setBatchFilters = (
    callback:
      | ((prevFilters: IVBillBatchDetailsFilters) => Partial<IVBillBatchDetailsFilters>)
      | Partial<IVBillBatchDetailsFilters>,
  ) => {
    const newFilters = isFunction(callback) ? callback(this.batchFilters) : callback;
    this.batchFilters = { ...this.batchFilters, ...newFilters };
  };

  resetBatchFilters = () => {
    this.batchFilters = batchDetailsFiltersInitParams;
  };

  getBatchDetails = (data: { batchId: number; organizationId: string }, forceUpdate?: boolean) => {
    this.batchDetails.cachedLoad(
      async () => {
        const resp = await VBillGetBatchList({
          filters: { batchIds: [data.batchId], organizationId: data.organizationId },
          pagination: { page: 1 },
        });

        return resp.getBatchList.data?.items[0]
          ? { ...resp.getBatchList.data.items[0], settings: resp.getBatchList.data.settings }
          : undefined;
      },
      [data],
      {
        forceUpdate,
      },
    );
  };

  getchildBatchListDetails = (
    data: {
      filters: { batchId: number; organizationId: string } & Omit<
        IVBillBatchListFiltersInput,
        "organizationId" | "parentBatchId"
      >;
      pagination?: IVBillBatchPaginationInput;
    },
    forceUpdate?: boolean,
  ) => {
    this.childBatchListDetails.cachedLoad(
      async () =>
        (
          await VBillGetBatchList({
            filters: {
              ...transformDatesAsSODUTCIsoString(data.filters),
              parentBatchId: data.filters.batchId,
              organizationId: data.filters.organizationId,
              includeSubBatches: true,
            },
            pagination: data.pagination ?? { page: 1 },
          })
        ).getBatchList.data,
      [data],
      {
        forceUpdate,
      },
    );
  };

  getVBillAdditionalMappingsSettings = (data: IVBillVBillSettingsQueryVariables, forceUpdate?: boolean) => {
    this.vBillAdditionalMappingsSettings.cachedLoad(
      async () => await (await VBillSettings(data)).settings.vBill.additionalMappings,
      [data],
      {
        forceUpdate,
      },
    );
  };

  getBatchNestedTree = (data: IBatchNestedTreeReqData, forceUpdate?: boolean) => {
    this.batchNestedTree.cachedLoad(async () => await this._genereateBatchNestedTree(data), [data], {
      forceUpdate,
    });
  };

  private _genereateBatchNestedTree = async ({ organizationId, batchId }: IBatchNestedTreeReqData) => {
    // recursive get nested batches
    const { batchData, batchChildren } = await VBillGetBatchTree({
      organizationId,
      batchIds: [batchId],
      parentBatchId: batchId,
    });
    if (batchChildren.data?.total) {
      const children = await Promise.all(
        (batchChildren.data.items ?? []).map((batch) =>
          this._genereateBatchNestedTree({ organizationId, batchId: batch.id }),
        ),
      );
      if (!!batchData.data?.items[0]) {
        const batch: IBatchNestedTree = {
          ...batchData.data.items[0],
          children: (children ?? []).filter((batch): batch is IBatchNestedTree => !!batch),
        };

        return batch;
      }
    }
    if (batchData.data?.items[0]) {
      const batch: IBatchNestedTree = {
        ...batchData.data.items[0],
        children: [],
      };

      return batch;
    }
  };

  getVBillBatchAdditionalMappingsSettings = (
    data: IVBillVBillCompaniesSettingsQueryVariables,
    forceUpdate?: boolean,
  ) => {
    this.vBillBatchAdditionalMappingsSettings.cachedLoad(
      async () => await (await VBillCompaniesSettings(data)).companiesSettings.vBill.additionalMappings,
      [data],
      {
        forceUpdate,
      },
    );
  };

  getBatchDetailsReactionsStats = (forceUpdate?: boolean) => {
    if (!this.batchDetails.data) {
      return;
    }

    const filters = {
      parentType: IVBillReactionParentType.VbillBatch,
      parentKey: `${this.batchDetails.data.id}`,
    };

    this.batchDetailsReactionsStats.cachedLoad(
      async () => (await VBillGetReactionsStats({ filters })).getReactionsStats.groups,
      [filters],
      {
        forceUpdate,
      },
    );
  };

  getChildBatchListDetailsReactionsStats = (forceUpdate?: boolean) => {
    if (!this.childBatchListDetails?.data?.items?.length) {
      return;
    }

    const filters = this.childBatchListDetails.data.items.map(({ id }) => ({
      parentType: IVBillReactionParentType.VbillBatch,
      parentKey: `${id}`,
    }));

    this.childBatchListDetailsReactionsStats.cachedLoad(
      async () => (await VBillGetReactionsStats({ filters })).getReactionsStats.groups,
      [filters],
      {
        forceUpdate,
      },
    );
  };

  getBatchSelectedVBills = async (
    data: IVBillVBillGetBillsQueryVariables,
    forceUpdate?: boolean,
    markAsLoading?: boolean,
  ) => {
    await this.batchSelectedVBills.cachedLoad(
      async () =>
        (
          await VBillGetBills({
            ...data,
            filters: { ...data.filters, ...transformDatesAsSODUTCIsoString(data.filters) },
          })
        ).getBills,
      [data],
      {
        forceUpdate,
        markAsLoading,
      },
    );
  };

  getBatchSelectedVBillsReactionsStats = (forceUpdate?: boolean) => {
    if (!this.batchSelectedVBills.data?.items.length) {
      return;
    }

    const filters = this.batchSelectedVBills.data.items.map(({ id }) => ({
      parentType: IVBillReactionParentType.Vbill,
      parentKey: `${id}`,
    }));

    this.batchSelectedVBillsReactionsStats.cachedLoad(
      async () => (await VBillGetReactionsStats({ filters })).getReactionsStats.groups,
      [filters],
      {
        forceUpdate,
      },
    );
  };

  getBatchAvailableVBills = async (
    data: IVBillVBillGetBillsQueryVariables,
    forceUpdate?: boolean,
    markAsLoading?: boolean,
  ) => {
    await this.batchAvailableVBills.cachedLoad(
      async () =>
        (
          await VBillGetBills({
            ...data,
            filters: { ...data.filters, ...transformDatesAsSODUTCIsoString(data.filters) },
          })
        ).getBills,
      [data],
      {
        forceUpdate,
        markAsLoading,
      },
    );
  };

  getBatchMembers = async (data: IVBillVBillGetBatchMembersQueryVariables, forceUpdate?: boolean) => {
    await this.batchMembers.cachedLoad(async () => (await VBillGetBatchMembers(data)).getBatchMembers.data, [data], {
      forceUpdate,
    });
  };

  addBatchMembers = async (data: IVBillVBillAddMembersToBatchMutationVariables) => {
    const resp = await VBillAddMembersToBatch(data);

    runInAction(() => {
      if (resp.addMembersToBatch.data) {
        this.batchMembers._dataObsevable = resp.addMembersToBatch.data;
      }
    });
  };

  removeBatchMembers = async (data: IVBillVBillRemoveMembersFromBatchMutationVariables) => {
    const resp = await VBillRemoveMembersFromBatch(data);

    runInAction(() => {
      if (resp.removeMembersFromBatch.data) {
        this.batchMembers._dataObsevable = resp.removeMembersFromBatch.data;
      }
    });
  };

  updateBatchDetails = async (data: IVBillVBillUpdateBatchMutationVariables) => {
    const resp = await VBillUpdateBatch(data);

    runInAction(() => {
      if (resp.updateBatch.data) {
        this.batchDetails._dataObsevable = resp.updateBatch.data;
      }
    });
  };

  createBatchPayments = async (data: IVBillVBillCreateBatchPaymentsMutationVariables) => {
    const resp = await VBillCreateBatchPayments(data);

    runInAction(() => {
      if (resp.createBatchPayments) {
        // TODO: what to do with createBatchPayments.payments
        this.batchDetails._dataObsevable = resp.createBatchPayments.batch;
      }
    });

    return resp;
  };

  getBatchStats = async (data: IVBillVBillGetBillsStatsQueryVariables, forceUpdate?: boolean) => {
    this.batchStats.cachedLoad(
      async () =>
        (
          await VBillGetBillsStats({
            ...data,
            filters: { ...data.filters, ...transformDatesAsSODUTCIsoString(data.filters) },
          })
        ).getBillsStats,
      [data],
      {
        forceUpdate,
      },
    );
  };

  addVBillToBatch = async (data: IVBillVBillAddInvoicesToBatchMutationVariables) => {
    const resp = await VBillAddInvoicesToBatch(data);

    runInAction(() => {
      if (resp.addInvoicesToBatch.data) {
        this.batchDetails._dataObsevable = resp.addInvoicesToBatch.data;
      }
    });
  };

  removeVBillFromBatch = async (data: IVBillVBillRemoveInvoicesFromBatchMutationVariables) => {
    const resp = await VBillRemoveInvoicesFromBatch(data);

    runInAction(() => {
      if (resp.removeInvoicesFromBatch.data) {
        this.batchDetails._dataObsevable = resp.removeInvoicesFromBatch.data;
      }
    });
  };
}
