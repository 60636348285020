import { Box, SxProps, Theme, Typography } from "@mui/material";
import { chatMessageMentionTemplateTest } from "common/helpers/utils";
import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import { IChatChatIoMessageContentBlockOutput, IChatContentBlockMessageRelatedType } from "generated/sdk.chat";
import { CHAT_MSG_REACTION_INLINE_PREFIX } from "modules/Chat/utils/utils";
import { FunctionComponent, ReactNode } from "react";
import { COLORS } from "themes/default";

interface IChatMessageBlockContentProps {
  content: IChatChatIoMessageContentBlockOutput;
  isMessageOwner: boolean;
  prefixText?: ReactNode;
  sx?: SxProps<Theme>;
}

export const ChatMessageBlockContent: FunctionComponent<IChatMessageBlockContentProps> = ({
  content: { message, related },
  isMessageOwner,
  prefixText,
  sx,
}) => {
  const messageBlocks = message?.split(chatMessageMentionTemplateTest);

  return (
    <Typography
      sx={{
        fontSize: 14,
        backgroundColor: isMessageOwner ? COLORS.borderColor : COLORS.mercury,
        borderRadius: "10px",
        padding: "10px",
        wordBreak: "break-all",
        ...sx,
      }}
    >
      {prefixText}
      {(messageBlocks ?? []).map((block, index) => {
        if (block.startsWith(`{{${IChatContentBlockMessageRelatedType.Account}:`) && block.endsWith("}}")) {
          const accountIdMatch = block.substring(
            3 + IChatContentBlockMessageRelatedType.Account.length,
            block.length - 2,
          );

          const member = related
            ?.filter(({ related }) => related.type === IChatContentBlockMessageRelatedType.Account)
            .find(({ related }) => related.key === accountIdMatch);

          return (
            member && (
              <Box key={`${block}-${index}`} component="span" sx={{ textDecoration: "underline" }}>
                @{member?.related.label}
              </Box>
            )
          );
        }

        if (block.startsWith(`{{${IChatContentBlockMessageRelatedType.Bill}:`) && block.endsWith("}}")) {
          const accountIdMatch = block.substring(3 + IChatContentBlockMessageRelatedType.Bill.length, block.length - 2);
          const bill = related
            ?.filter(({ related }) => related.type === IChatContentBlockMessageRelatedType.Bill)
            ?.find(({ related }) => related.key === accountIdMatch);

          return (
            bill && (
              <Box
                key={`${block}-${index}`}
                component="span"
                role="button"
                tabIndex={0}
                // sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                #{bill?.related.label}
              </Box>
            )
          );
        }

        if (block.startsWith(`{{${CHAT_MSG_REACTION_INLINE_PREFIX}:`) && block.endsWith("}}")) {
          const accountIdMatch = block.substring(`{{${CHAT_MSG_REACTION_INLINE_PREFIX}:`.length, block.length - 2);
          const reaction = related
            ?.filter(({ related }) => related.type === IChatContentBlockMessageRelatedType.Reaction)
            ?.find(({ related }) => related.key === accountIdMatch);

          return (
            reaction && (
              <Box
                key={`${block}-${index}`}
                component="span"
                role="img"
                dangerouslySetInnerHTML={{
                  __html: chatStaticEmojiList.find(({ name }) => name === reaction.related.label)?.hex ?? "",
                }}
              />
            )
          );
        }

        return <span key={`${block}-${index}`}>{block}</span>;
      })}
    </Typography>
  );
};
